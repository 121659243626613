<script setup lang="ts">

</script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.33 23.33">
    <path
      d="m8.52,22.4v-10.73h-2.34v-3.62c.1,0,.21-.02.32-.02.57,0,1.14-.01,1.7,0,.25,0,.33-.06.33-.32-.01-.93-.01-1.84,0-2.77,0-1.04.3-1.98,1.04-2.74.72-.73,1.64-1.13,2.64-1.19,1.61-.09,3.22-.08,4.83-.11.02,0,.04.02.07.04v3.71c-.12,0-.23.01-.33.01h-2.19c-.74,0-1.17.44-1.17,1.17s0,1.38,0,2.07c0,.02.01.04.03.1h3.7c-.16,1.24-.3,2.42-.46,3.64h-3.29v10.75c-1.64-.01-3.23-.01-4.88-.01Z"
      style="fill:currentColor; stroke-width:0px;" />
  </svg>
</template>

<style scoped>

</style>
